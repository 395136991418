<template>
	<div
		class="settings-wrapper"
	>
		<q-item tag="label" dense class="q-my-sm" v-if="!switch_setting.is_additional_emails">
			<q-item-section>
				<q-item-label>{{ switch_setting.label }}</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="value" />
			</q-item-section>
		</q-item>
		<div
			class="q-px-md"
			v-if="!switch_setting.is_additional_emails &&
				!['is_audit_active', 'add_additional_emails_to_distribution_list'].includes(switch_setting.name)"
		>
			<q-select
				multiple
				v-model="recipient_value"
				use-input
				class="q-my-sm"
				:options="options"
				label="Recipients"
				:disable="disabled"
				@filter=filterFn
			/>
		</div>
		<div v-if="switch_setting.is_additional_emails && switch_setting.condition">
			<q-input
				class="q-px-md"
				:label="switch_setting.label"
				v-model="domain_email_value"
				:type="switch_setting.type"
				:error="has_error"
				:error-message="error_message"
				@keydown.enter.prevent="addValue(domain_email_value)"
			>
				<template v-slot:append>
					<q-icon
						name="check"
						class="cursor-pointer"
						@click.stop="addValue(domain_email_value)"
					/>
				</template>
			</q-input>
			<div>
				<span
					v-for="value in selected_list"
					:key="value"
				>
					<q-chip
						outline
						class="q-px-md"
						color="primary"
						removable
						@remove="removeValue(value, selected_list)"
					>
						<span v-if="value.email">{{value.email}}</span>
						<span v-else>{{value}}</span>
					</q-chip>
				</span>
			</div>
		</div>
		<div class="q-px-md" v-if="display_duration">
			<q-select
				v-model="duration"
				:options="audit_recipients_options_duration"
				label="Choose how to receive these notifications"
				:disable="disabled"
			/>
		</div>
		<div
			class="q-px-md"
			v-if="
				$store.getters.audit_settings.add_additional_emails_to_distribution_list &&
				switch_setting.additional_users_value
			"
		>
			<q-select
				multiple
				v-model="additional_users_value"
				:options="audit_additional_recipients"
				label="Additional recipients"
				:disable="disabled"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AuditFields',
	props: {
		switch_setting: Object,
	},
	data() {
		return {
			audit_recipients_options_duration: [
				{label: 'Monthly', value: 'monthly'},
				{label: 'Quarterly', value: 'quarterly'},
				{label: '6-monthly', value: '6-monthly'}
			],
			domain_email_value: '',
			has_error: false,
			error_message: '',
			options: []
		}
	},
	computed: {
		...mapGetters([
			'audit_recipients_options',
			'audit_domains',
			'audit_additional_recipients',
		]),
		disabled() {
			return (
				!this.$store.getters.audit_settings[this.switch_setting.value]
			)
		},
		display_duration() {
			return (this.switch_setting.name != 'is_audit_active' &&
			[
				'not-logged-in-users',
				'suppressed-users',
				'inactive-users',
				'no-number-users'
			].includes(this.switch_setting.name))
		},
		value: {
			get() {
				if (
					this.$store.getters.audit_settings[this.switch_setting.value] ==
					undefined
				) {
					return false
				}
				return this.$store.getters.audit_settings[this.switch_setting.value]
			},
			set(val) {
				let new_val = JSON.parse(
					JSON.stringify(this.$store.getters.audit_settings)
				)
				new_val[this.switch_setting.value] = val
				if (this.switch_setting.mutually_exclusive && val) {
					new_val[this.switch_setting.mutually_exclusive] = !val
					new_val[this.switch_setting.mutually_exclusive_multiselect_value] = []
					new_val[this.switch_setting.mutually_exclusive_additional_users_value] = []
				}
				if (this.switch_setting.duration && val && !this.$store.getters.audit_settings[this.switch_setting.duration]) {
					new_val[this.switch_setting.duration] = {label: 'Monthly', value: 'monthly'}
				}
				// empty the recipients array when turning off its corresponding slider
				if (!val && this.switch_setting.value !== 'is_audit_active') {
					if (new_val[this.switch_setting.additional_users_value]) {
						new_val[this.switch_setting.additional_users_value] = []
					}
					if (new_val[this.switch_setting.multiselect_value]) {
						new_val[this.switch_setting.multiselect_value] = []
					}
					if (new_val[this.switch_setting.duration]) {
						new_val[this.switch_setting.duration] = []
					}
				}
				this.updateAuditSettings(new_val)
			},
		},
		recipient_value: {
			get() {
				return this.$store.getters.audit_settings[
					this.switch_setting.multiselect_value
				]
			},
			set(val) {
				let new_val = JSON.parse(
					JSON.stringify(this.$store.getters.audit_settings)
				)

				new_val[this.switch_setting.multiselect_value] = val
				this.updateAuditSettings(new_val)
			},
		},
		additional_users_value: {
			get() {
				return this.$store.getters.audit_settings[
					this.switch_setting.additional_users_value
				]
			},
			set(val) {
				let new_val = JSON.parse(
					JSON.stringify(this.$store.getters.audit_settings)
				)

				new_val[this.switch_setting.additional_users_value] = val
				this.updateAuditSettings(new_val)
			},
		},
		selected_list: {
			get() {
				if (this.switch_setting.value == 'domain'){
					return this.$store.getters.audit_domains
				}else{
					return this.$store.getters.audit_additional_recipients
				}
			},
			set(val) {
				if (this.switch_setting.value == 'domain'){
					this.updateAuditDomains(val)
				}else{
					this.updateAuditAdditionalRecipients(val)
				}
			}
		},
		duration: {
			get() {
				return this.$store.getters.audit_settings[
					this.switch_setting.duration
				]
			},
			set(val) {
				let new_val = JSON.parse(
					JSON.stringify(this.$store.getters.audit_settings)
				)
				new_val[this.switch_setting.duration] = val
				this.updateAuditSettings(new_val)
			}
		},
	},
	methods: {
		...mapActions(['updateAuditSettings', 'updateAuditAdditionalRecipients', 'updateAuditDomains']),
		addValue(value){
			this.has_error = false
			this.error_message = ''
			if (!value.length){
				this.has_error = true
				this.error_message = 'Please enter a value'
				return
			}
			if (this.switch_setting.value == 'domain'){
				let formatted_value = value[0] === '@' ? value : '@' + value
				this.selected_list.push(formatted_value)
				this.domain_email_value = ''
			}else{
				let email_domain = value.split('@')
				let email_domains = this.$store.getters.audit_domains
				email_domain = email_domain[email_domain.length - 1]
				if (!email_domains.includes('@' + email_domain)){
					this.has_error = true
					this.error_message = 'The email domain is not set in the allowed domains'
					return
				}
				this.selected_list.push(value)
				this.domain_email_value = ''
			}
		},
		removeValue(value, selected_list){
			selected_list.splice(selected_list.indexOf(value), 1)
			if (this.switch_setting.value == 'domain'){
				let emails_list = this.$store.getters.audit_additional_recipients
				let new_list = []
				for (let val of emails_list){
					if (!val.includes(value)){
						new_list.push(val)
					}else{
						this.removeValueFromList(val)
					}
				}
				this.updateAuditAdditionalRecipients(new_list)
			}
			else{
				this.removeValueFromList(value)
			}
			this.selected_list = selected_list
		},
		removeValueFromList(value){
			let lists = [
				this.$store.getters.audit_settings['for_imports_this_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_imports_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_on_call_imports_this_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_on_call_imports_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_media_this_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_media_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_disabled_user_this_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_disabled_user_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_invalid_phone_email_this_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_invalid_phone_email_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_on_call_this_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_on_call_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['additional_recipients_phone_invoke_this_dataset'],
				this.$store.getters.audit_settings['for_phone_invoke_children_dataset_audit_recipients'],
				this.$store.getters.audit_settings['for_not_logged_in_users_audit_recipients'],
				this.$store.getters.audit_settings['for_suppressed_users_audit_recipients'],
				this.$store.getters.audit_settings['for_inactive_users_audit_recipients'],
				this.$store.getters.audit_settings['for_no_number_users_audit_recipients'],
			]
			for (let list of lists){
				if (list && list.includes(value)){
					list.splice(list.indexOf(value), 1)
				}
			}
		},
		filterFn (val, update) {
			if (val === '') {
				update(() => {
					this.options = this.audit_recipients_options
				})
				return
			}

			update(() => {
				const needle = val.toLowerCase()
				this.options = this.audit_recipients_options.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
			})
		}
	},
}
</script>
