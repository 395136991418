<template>
	<div>
		<h1 class="setting-title">
			Auto email configuration options: {{ cg.name }}
		</h1>
		<h2
			v-if="audit_message"
			class="text-h6"
		>
			{{ audit_message }}
		</h2>
		<div
			v-for="switch_item in switch_items"
			:key="switch_item.name"
		>
			<audit-fields
				:switch_setting="switch_item"
				v-if="switch_item.condition"
			/>
		</div>
		<save-button @save="save" />
	</div>
</template>

<script>
import AuditFields from '@/components/datasets/edit/audit/AuditFields.vue'
import { mapActions, mapGetters } from 'vuex'
import SaveButton from '@/components/datasets/utils/SaveButton.vue'

export default {
	name: 'Audit',
	components: {
		AuditFields,
		SaveButton,
	},
	computed: {
		...mapGetters(['audit_message', 'audit_domains']),
		switch_items() {
			return [
				{
					condition: true,
					label: 'Configure your Audit Emails and distribution lists',
					name: 'is_audit_active',
					value: 'is_audit_active',
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label: 'Configure additional recipients for Audit Emails and distribution list',
					name: 'add_additional_emails_to_distribution_list',
					value: 'add_additional_emails_to_distribution_list',
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.audit_settings.add_additional_emails_to_distribution_list,
					label: 'Add allowed recipient domain',
					name: 'domains_list',
					value: 'domain',
					type: 'text',
					is_additional_emails: true,
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.audit_settings.add_additional_emails_to_distribution_list,
					label: 'Add additional recipient email',
					name: 'emails_list',
					value: 'email',
					type: 'email',
					is_additional_emails: true,
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label: 'User imports for this Dataset only',
					name: 'user-imports-without-children',
					value: 'imports',
					multiselect_value: 'imports_this_dataset_audit_recipients',
					additional_users_value: 'for_imports_this_dataset_audit_recipients',
					mutually_exclusive: 'imports_children',
					mutually_exclusive_multiselect_value:
						'imports_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_imports_children_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.has_children,
					label: 'User imports for this Dataset plus any child Datasets',
					name: 'user-imports-for-this-dataset-plus-children',
					value: 'imports_children',
					multiselect_value: 'imports_children_dataset_audit_recipients',
					additional_users_value: 'for_imports_children_dataset_audit_recipients',
					mutually_exclusive: 'imports',
					mutually_exclusive_multiselect_value:
						'imports_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_imports_this_dataset_audit_recipients'

				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions.on_call_period,
					label: 'On call user imports for this Dataset only',
					name: 'on-call-user-imports-without-children',
					value: 'on_call_imports',
					multiselect_value: 'on_call_imports_this_dataset_audit_recipients',
					additional_users_value: 'for_on_call_imports_this_dataset_audit_recipients',
					mutually_exclusive: 'on_call_imports_children',
					mutually_exclusive_multiselect_value:
						'on_call_imports_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_on_call_imports_children_dataset_audit_recipients'

				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions.on_call_period &&
						this.$store.getters.has_children,
					label:
						'On call user imports for this Dataset plus any child Datasets',
					name: 'on-call-user-imports-plus-children',
					value: 'on_call_imports_children',
					multiselect_value:
						'on_call_imports_children_dataset_audit_recipients',
					additional_users_value: 'for_on_call_imports_children_dataset_audit_recipients',
					mutually_exclusive: 'on_call_imports',
					mutually_exclusive_multiselect_value:
						'on_call_imports_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_on_call_imports_this_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions
							.media_library_feature,
					label:
						'Media library uploads, amendments or deletions for this Dataset only',
					name: 'media-file',
					value: 'media_file',
					multiselect_value: 'media_this_dataset_audit_recipients',
					additional_users_value: 'for_media_this_dataset_audit_recipients',
					mutually_exclusive: 'media_file_children',
					mutually_exclusive_multiselect_value:
						'media_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_media_children_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions
							.media_library_feature &&
						this.$store.getters.has_children,
					label:
						'Media library uploads, amendments or deletions for this Dataset plus any child Datasets',
					name: 'media-file-children',
					value: 'media_file_children',
					multiselect_value: 'media_children_dataset_audit_recipients',
					additional_users_value: 'for_media_children_dataset_audit_recipients',
					mutually_exclusive: 'media_file',
					mutually_exclusive_multiselect_value:
						'media_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_media_this_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label: 'Disabled user login attempts for this Dataset only',
					name: 'disabled-user',
					value: 'disabled_user',
					multiselect_value: 'disabled_user_this_dataset_audit_recipients',
					additional_users_value: 'for_disabled_user_this_dataset_audit_recipients',
					mutually_exclusive: 'disabled_user_children',
					mutually_exclusive_multiselect_value:
						'disabled_user_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_disabled_user_children_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.has_children,
					label:
						'Disabled user login attempts for this Dataset only plus any child Dataset',
					name: 'disabled-user-children',
					value: 'disabled_user_children',
					multiselect_value: 'disabled_user_children_dataset_audit_recipients',
					additional_users_value: 'for_disabled_user_children_dataset_audit_recipients',
					mutually_exclusive: 'disabled_user',
					mutually_exclusive_multiselect_value:
						'disabled_user_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_disabled_user_this_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters
							.send_notifications_to_admins_about_invalid_recipients,
					label:
						'Notification of invalid phone numbers and/or email addresses for this Dataset only',
					name: 'invalid-phone-email',
					value: 'invalid_phone_email',
					multiselect_value:
						'invalid_phone_email_this_dataset_audit_recipients',
					additional_users_value: 'for_invalid_phone_email_this_dataset_audit_recipients',
					mutually_exclusive: 'invalid_phone_email_children',
					mutually_exclusive_multiselect_value:
						'invalid_phone_email_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_invalid_phone_email_children_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters
							.send_notifications_to_admins_about_invalid_recipients &&
						this.$store.getters.has_children,
					label:
						'Notification of invalid phone numbers and/or email addresses for this Dataset plus any child Dataset',
					name: 'invalid-phone-email-children',
					value: 'invalid_phone_email_children',
					multiselect_value:
						'invalid_phone_email_children_dataset_audit_recipients',
					additional_users_value: 'for_invalid_phone_email_children_dataset_audit_recipients',
					mutually_exclusive: 'invalid_phone_email',
					mutually_exclusive_multiselect_value:
						'invalid_phone_email_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_invalid_phone_email_this_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions
							.minimum_staffing_requirements,
					label:
						'On call minimum staffing requirements alerts for this Dataset only',
					name: 'on-call',
					value: 'on_call',
					multiselect_value: 'on_call_this_dataset_audit_recipients',
					additional_users_value: 'for_on_call_this_dataset_audit_recipients',
					mutually_exclusive: 'on_call_children',
					mutually_exclusive_multiselect_value:
						'on_call_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_on_call_children_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions
							.minimum_staffing_requirements &&
						this.$store.getters.has_children,
					label:
						'On call minimum staffing requirements alerts for this Dataset plus any child Dataset',
					name: 'on-call-children',
					value: 'on_call_children',
					multiselect_value: 'on_call_children_dataset_audit_recipients',
					additional_users_value: 'for_on_call_children_dataset_audit_recipients',
					mutually_exclusive: 'on_call',
					mutually_exclusive_multiselect_value:
						'on_call_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_on_call_this_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions.phone_invoke,
					label:
						'Unsuccessful/incomplete phone invoke alerts for this Dataset only',
					name: 'phone-invoke',
					value: 'phone_invoke',
					multiselect_value: 'phone_invoke_this_dataset_audit_recipients',
					additional_users_value: 'for_phone_invoke_this_dataset_audit_recipients',
					mutually_exclusive: 'phone_invoke_children',
					mutually_exclusive_multiselect_value:
						'phone_invoke_children_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_phone_invoke_children_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active &&
						this.$store.getters.customer_group_permissions.phone_invoke &&
						this.$store.getters.has_children,
					label:
						'Unsuccessful/incomplete phone invoke alerts for this Dataset plus any child Datasets',
					name: 'phone-invoke-children',
					value: 'phone_invoke_children',
					multiselect_value: 'phone_invoke_children_dataset_audit_recipients',
					additional_users_value: 'for_phone_invoke_children_dataset_audit_recipients',
					mutually_exclusive: 'phone_invoke',
					mutually_exclusive_multiselect_value:
						'phone_invoke_this_dataset_audit_recipients',
					mutually_exclusive_additional_users_value:
						'for_phone_invoke_this_dataset_audit_recipients'
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label:
						'Users who have not logged in XXX months for this Dataset',
					name: 'not-logged-in-users',
					value: 'not_logged_in_users',
					multiselect_value: 'not_logged_in_users_audit_recipients',
					additional_users_value: 'for_not_logged_in_users_audit_recipients',
					duration: 'not_logged_in_users_duration',
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label:
						'Users with suppressed email addresses for this Dataset',
					name: 'suppressed-users',
					value: 'suppressed_users',
					multiselect_value: 'suppressed_users_audit_recipients',
					additional_users_value: 'for_suppressed_users_audit_recipients',
					duration: 'suppressed_users_duration',
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label: 'Inactive users for this Dataset',
					name: 'inactive-users',
					value: 'inactive_users',
					multiselect_value: 'inactive_users_audit_recipients',
					additional_users_value: 'for_inactive_users_audit_recipients',
					duration: 'inactive_users_duration',
				},
				{
					condition:
						this.$store.getters.audit_settings &&
						this.$store.getters.audit_settings.is_audit_active,
					label: 'Users without a phone number for this Dataset',
					name: 'no-number-users',
					value: 'no_number_users',
					multiselect_value: 'no_number_users_audit_recipients',
					additional_users_value: 'for_no_number_users_audit_recipients',
					duration: 'no_number_users_duration',
				},
			]
		},
		cg() {
			return this.$store.getters.customer_groups.filter(
				(cg) => cg.id == this.$route.params.id
			)[0]
		},
	},
	methods: {
		...mapActions(['saveAuditSettings', 'fetchUser']),
		async save() {
			const response = await this.saveAuditSettings(this.$route.params.id)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.$q.notify(response)
			this.$store.commit('setAuditMessage', '')
			await this.fetchUser()
		},
	},
}
</script>

<style lang="scss" scoped>
.audit-message {
	font-size: 1.125rem;
	line-height: 1.75rem;
	text-align: left;
	padding-bottom: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-bottom-width: 1px;
	cursor: default;
}
</style>
